import {Reducer, useCallback, useEffect, useMemo, useReducer} from 'react';

import {SharedPanelZoomState} from '../SharedPanelZoomContext';
import {HistoryPoint, PartialZoom} from '../types';
import {useDeepEqualValue} from './../../../util/hooks';
import {calcDomainRange} from './calcDomainRange';
import {PartialMaybeDomain} from './types';
import {isMetricKeyAbsoluteRuntime} from './utils';

export const DEFAULT_ZOOM = {
  xAxisMax: null,
  xAxisMin: null,
  yAxisMax: null,
  yAxisMin: null,
};

export const DEFAULT_USER_ZOOM_STEP = {
  xAxisMin: null,
  xAxisMax: null,
};

type PanelZoomReducerState = {
  xAxisBoundary: {
    min: number | null;
    max: number | null;
  };
  userZoom: {
    xAxisMax: number | null;
    xAxisMin: number | null;
    yAxisMax: number | null;
    yAxisMin: number | null;
  };
  userZoomStep: {
    xAxisMin: number | null;
    xAxisMax: number | null;
  };
  xAxisKey: string;
};

type PanelZoomReducerActions =
  | {
      type: 'panelZoom/changeXAxisKey';
      payload: string;
    }
  | {
      type: 'panelZoom/resetUserZoom';
      payload: PartialZoom;
    }
  | {
      type: 'panelZoom/sharedPanelZoomChange';
      payload: SharedPanelZoomState;
      history?: HistoryPoint[];
    }
  | {
      type: 'panelZoom/userZoomChange';
      payload: PartialZoom;
    }
  | {
      type: 'panelZoom/userZoomChangeStep';
      payload: PartialZoom;
      history: HistoryPoint[];
    }
  | {
      type: 'panelZoom/toggleLogging';
      payload: boolean;
    };

function calcUserZoomStep(
  userZoom: {
    xAxisMin: number | null;
    xAxisMax: number | null;
  },
  xAxisKey: string,
  history: HistoryPoint[]
): {
  xAxisMin: number | null;
  xAxisMax: number | null;
} {
  // no special handling for a zoom out
  const emptyZoom = userZoom.xAxisMin == null && userZoom.xAxisMax == null;
  const emptyHistory = history.length === 0;
  if (emptyZoom || emptyHistory) {
    return {
      xAxisMin: null,
      xAxisMax: null,
    };
  }

  const result = calcDomainRange(
    // this is a safe case because we filtered off [null,null] above
    [userZoom.xAxisMin, userZoom.xAxisMax] as PartialMaybeDomain,
    history,
    xAxisKey
  );

  return {
    xAxisMin: result[0] ?? null,
    xAxisMax: result[1] ?? null,
  };
}

export function panelZoomReducer(
  state: PanelZoomReducerState,
  action: PanelZoomReducerActions
) {
  switch (action.type) {
    case 'panelZoom/toggleLogging': {
      return {
        ...state,
        isLoggingOn: action.payload,
      };
    }

    /**
     * When the x-axis value changes we reset any active user zooms
     */
    case 'panelZoom/changeXAxisKey': {
      return {
        ...state,
        xAxisKey: action.payload,
        userZoom: DEFAULT_ZOOM,
      };
    }

    /**
     * When a config value changes we reset any active user zooms
     */
    case 'panelZoom/resetUserZoom': {
      return {
        ...state,
        userZoom: DEFAULT_ZOOM,
        userZoomStep: DEFAULT_USER_ZOOM_STEP,
      };
    }

    /**
     * Note: when user zooms happen elsewhere, we still update the user zoom for this panel. The logic for making sure the user zoom doesn't escape the boundaries is handled outside the reducer in the consuming hook
     */
    case 'panelZoom/sharedPanelZoomChange': {
      if (state.xAxisKey !== action.payload.xAxis) {
        return state;
      }

      // no-op shared panel zooms that match the current user zoom
      if (
        state.userZoom.xAxisMin === action.payload.xMin &&
        state.userZoom.xAxisMax === action.payload.xMax
      ) {
        return state;
      }

      const userZoomStep = calcUserZoomStep(
        {
          xAxisMin: action.payload.xMin ?? null,
          xAxisMax: action.payload.xMax ?? null,
        },
        state.xAxisKey,
        action.history ?? []
      );

      return {
        ...state,
        userZoomStep,
        userZoom: {
          ...state.userZoom,
          xAxisMin: action.payload.xMin ?? null,
          xAxisMax: action.payload.xMax ?? null,
        },
      };
    }

    case 'panelZoom/userZoomChangeStep': {
      const userZoomStep = calcUserZoomStep(
        {
          xAxisMin: action.payload.xAxisMin ?? null,
          xAxisMax: action.payload.xAxisMax ?? null,
        },
        state.xAxisKey,
        action.history
      );

      return {
        ...state,
        userZoomStep,
        userZoom: {
          xAxisMin: action.payload.xAxisMin ?? null,
          xAxisMax: action.payload.xAxisMax ?? null,
          yAxisMin: action.payload.yAxisMin ?? null,
          yAxisMax: action.payload.yAxisMax ?? null,
        },
      };
    }

    case 'panelZoom/userZoomChange': {
      return {
        ...state,
        userZoom: {
          xAxisMin: action.payload.xAxisMin ?? null,
          xAxisMax: action.payload.xAxisMax ?? null,
          yAxisMin: action.payload.yAxisMin ?? null,
          yAxisMax: action.payload.yAxisMax ?? null,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const usePanelZoomReducer = ({
  coordinateZooming,
  configZoom,
  history,
  sharedPanelZoomConfig,
  xAxisKey,
}: {
  coordinateZooming: boolean;
  configZoom: PartialZoom;
  history: HistoryPoint[];
  sharedPanelZoomConfig: SharedPanelZoomState;
  xAxisKey: string;
}) => {
  const [state, dispatch] = useReducer<
    Reducer<PanelZoomReducerState, PanelZoomReducerActions>
  >(panelZoomReducer, {
    xAxisKey,
    xAxisBoundary: {
      min: null,
      max: null,
    },
    userZoom: DEFAULT_ZOOM,
    userZoomStep: {
      xAxisMin: null,
      xAxisMax: null,
    },
  });

  const configZoomMemo = useDeepEqualValue(configZoom);
  const userZoom = useDeepEqualValue(state.userZoom);

  useEffect(() => {
    if (coordinateZooming) {
      dispatch({
        type: 'panelZoom/sharedPanelZoomChange',
        payload: sharedPanelZoomConfig,
        history: isMetricKeyAbsoluteRuntime(state.xAxisKey) ? history : [],
      });
    }
    // do not run this effect when the history changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinateZooming, sharedPanelZoomConfig]);

  useEffect(() => {
    dispatch({
      type: 'panelZoom/resetUserZoom',
      payload: configZoomMemo,
    });
    // do not run this effect when the logger changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configZoomMemo]);

  const handleUserZoomInSteps = useCallback(
    (newZoom: PartialZoom) => {
      dispatch({
        type: 'panelZoom/userZoomChangeStep',
        payload: newZoom,
        history,
      });
    },
    [dispatch, history]
  );

  const handleUserZoom = useCallback(
    (newZoom: PartialZoom) => {
      dispatch({
        type: 'panelZoom/userZoomChange',
        payload: newZoom,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: 'panelZoom/changeXAxisKey',
      payload: xAxisKey,
    });
    // do not run this effect when the logger changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxisKey]);

  const queryZoom = useMemo(() => {
    const result = getZoomCoordinates(state.userZoom, {
      xAxisMin: configZoom.xAxisMin ?? null,
      xAxisMax: configZoom.xAxisMax ?? null,
      yAxisMin: configZoom.yAxisMin ?? null,
      yAxisMax: configZoom.yAxisMax ?? null,
    });
    return result;
  }, [state.userZoom, configZoom]);

  return useMemo(() => {
    const value = {
      handleUserZoom,
      handleUserZoomInSteps,
      isZooming: userZoom.xAxisMin != null || userZoom.xAxisMax != null,
      queryZoom,
      queryZoomStep: state.userZoomStep,
    };

    return value;
  }, [
    handleUserZoom,
    handleUserZoomInSteps,
    queryZoom,
    state.userZoomStep,
    userZoom,
  ]);
};

export function getZoomCoordinates(
  userZoom: {
    xAxisMin: number | null | undefined;
    xAxisMax: number | null | undefined;
    yAxisMin: number | null | undefined;
    yAxisMax: number | null | undefined;
  },
  configZoom: {
    xAxisMin: number | null | undefined;
    xAxisMax: number | null | undefined;
    yAxisMin: number | null | undefined;
    yAxisMax: number | null | undefined;
  }
) {
  return {
    xAxisMin: getQueryValMin(
      userZoom.xAxisMin,
      configZoom.xAxisMin,
      configZoom.xAxisMax
    ),
    xAxisMax: getQueryValMax(
      userZoom.xAxisMax,
      configZoom.xAxisMin,
      configZoom.xAxisMax
    ),
    yAxisMin: getQueryValMin(
      userZoom.yAxisMin,
      configZoom.yAxisMin,
      configZoom.yAxisMax
    ),
    yAxisMax: getQueryValMax(
      userZoom.yAxisMax,
      configZoom.yAxisMin,
      configZoom.yAxisMax
    ),
  };
}

export function getQueryValMin(
  userZoomValue: number | null | undefined,
  configMinValue: number | null | undefined,
  configMaxValue: number | null | undefined
): number | null {
  // if the user zoom value is null | undefined, return configMinValue or null
  if (userZoomValue == null) {
    return configMinValue ?? null;
  }

  // if the config values are null, return the user zoom value
  if (configMinValue == null && configMaxValue == null) {
    return userZoomValue;
  }

  // if userZoomValue exceeds configMaxValue, return default min
  if (configMaxValue != null && userZoomValue > configMaxValue) {
    return configMinValue ?? null;
  }

  // if userZoomValue is below configMinValue, return configMinValue, otherwise null
  if (configMinValue != null && userZoomValue < configMinValue) {
    return configMinValue;
  }

  // if userZoomValue is between configMinValue and configMaxValue, return userZoomValue
  if (
    configMinValue != null &&
    configMaxValue != null &&
    userZoomValue >= configMinValue &&
    userZoomValue <= configMaxValue
  ) {
    return userZoomValue;
  }

  // if configMinValue is null but configMaxValue exists, and userZoomValue is less than configMaxValue
  if (
    configMinValue == null &&
    configMaxValue != null &&
    userZoomValue < configMaxValue
  ) {
    return userZoomValue;
  }

  // if configMaxValue is null but configMinValue exists, and userZoomValue is greater than configMinValue
  if (
    configMaxValue == null &&
    configMinValue != null &&
    userZoomValue > configMinValue
  ) {
    return userZoomValue;
  }

  return null;
}

export function getQueryValMax(
  userZoomValue: number | null | undefined,
  configMinValue: number | null | undefined,
  configMaxValue: number | null | undefined
) {
  if (userZoomValue == null) {
    return configMaxValue ?? null;
  }

  // if the config values are null, return the user zoom value
  if (configMinValue == null && configMaxValue == null) {
    return userZoomValue;
  }

  // if userZoomValue exceeds configMaxValue, return configMaxValue
  if (configMaxValue != null && userZoomValue > configMaxValue) {
    return configMaxValue;
  }

  // if userZoomValue is below configMinValue, return configMinValue, otherwise null
  if (configMinValue != null && userZoomValue < configMinValue) {
    return configMaxValue ?? null;
  }

  // if userZoomValue is between configMinValue and configMaxValue, return userZoomValue
  if (
    configMinValue != null &&
    configMaxValue != null &&
    userZoomValue >= configMinValue &&
    userZoomValue <= configMaxValue
  ) {
    return userZoomValue;
  }

  // if configMinValue is null but configMaxValue exists, and userZoomValue is less than configMaxValue
  if (
    configMinValue == null &&
    configMaxValue != null &&
    userZoomValue < configMaxValue
  ) {
    return userZoomValue;
  }

  // if configMaxValue is null but configMinValue exists, and userZoomValue is greater than configMinValue
  if (
    configMaxValue == null &&
    configMinValue != null &&
    userZoomValue > configMinValue
  ) {
    return userZoomValue;
  }

  return null;
}
