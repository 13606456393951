// Functions for querying runs
import {notEmpty} from '@wandb/weave/common/util/obj';
import * as _ from 'lodash';

import {createGorillaHistorySpec} from '../../components/PanelRunsLinePlot/common';
import * as Generated from '../../generated/graphql';
import {propagateErrorsContext} from '../../util/errors';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import {sortToOrderString} from '../../util/queryts';
import {ServerDeltaOp, ServerResultDelta} from '../../util/runDeltas';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';
import {ApolloClient} from '../types';
import {
  checkServerDeltaQueryShadow,
  processShadow,
  unwrapRespBRSDQ,
  unwrapRespRSDQ,
} from './apiShadow';
import {RSDQ_Query_Data} from './apiTypes';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ServerQueryDelta} from './serverQuery_serverDelta';
import * as Types from './types';

const BRSDQ_NUM_BUCKETS = 1000;
// Convert a query into the variables needed to make the graphql request.
export function queryToGQLVars(
  query: Types.Query,
  filters: FilterTypes.Filter<RunTypes.Key>
): Generated.RunsStateQueryQueryVariables {
  return {
    aggregationKeys:
      query.fullAggregations === true ? undefined : query.aggregationKeys,
    configKeys: query.fullConfig === true ? undefined : query.configKeys,
    enableArtifactCounts: query.enableArtifactCounts,
    enableAggregations:
      query.aggregationKeys != null || query.fullAggregations === true,
    enableBasic: query.enableBasic || false,
    enableConfig: query.configKeys != null || query.fullConfig === true,
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableSampledHistory: query.historySpecs != null,
    enableSummary: query.summaryKeys != null || query.fullSummary === true,
    enableTags: query.enableTags,
    enableWandb: query.wandbKeys != null,
    entityName: query.entityName,
    filters: JSON.stringify(Filter.toMongo(filters)),
    groupKeys: query.grouping.map(Run.keyToServerPath),
    groupLevel: 0,
    limit: query.limit,
    order: sortToOrderString(query.sort),
    projectName: query.projectName,
    sampledHistorySpecs:
      query.historySpecs != null
        ? query.historySpecs.map(hs => JSON.stringify(hs))
        : [],
    summaryKeys: query.fullSummary === true ? undefined : query.summaryKeys,
    wandbKeys: query.wandbKeys,
  };
}

export function queryToGQLVarsWithProjectId(
  query: Types.Query,
  filters: FilterTypes.Filter<RunTypes.Key>
): Generated.RunsStateQueryWithProjectIdQueryVariables {
  return {
    aggregationKeys:
      query.fullAggregations === true ? undefined : query.aggregationKeys,
    configKeys: query.fullConfig === true ? undefined : query.configKeys,
    enableArtifactCounts: query.enableArtifactCounts,
    enableAggregations:
      query.aggregationKeys != null || query.fullAggregations === true,
    enableBasic: query.enableBasic || false,
    enableConfig: query.configKeys != null || query.fullConfig === true,
    enableHistoryKeyInfo: query.enableHistoryKeyInfo,
    enableSampledHistory: query.historySpecs != null,
    enableSummary: query.summaryKeys != null || query.fullSummary === true,
    enableTags: query.enableTags,
    enableWandb: query.wandbKeys != null,
    filters: JSON.stringify(Filter.toMongo(filters)),
    groupKeys: query.grouping.map(Run.keyToServerPath),
    groupLevel: 0,
    limit: query.limit,
    order: sortToOrderString(query.sort),
    internalId: query.internalProjectId ?? '',
    sampledHistorySpecs:
      query.historySpecs != null
        ? query.historySpecs.map(hs => JSON.stringify(hs))
        : [],
    summaryKeys: query.fullSummary === true ? undefined : query.summaryKeys,
    wandbKeys: query.wandbKeys,
  };
}

const EMPTY_SINGLE_RUNS_QUERY_RESULT = {
  totalRuns: 0,
  runs: [],
  lastUpdatedAt: new Date(0).toISOString(),
};

export const doSingleRunsQuery = (client: ApolloClient, query: Types.Query) => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    return Promise.resolve(EMPTY_SINGLE_RUNS_QUERY_RESULT);
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }
  return client
    .query<Generated.RunsStateQueryQuery>({
      query: Generated.RunsStateQueryDocument,
      fetchPolicy: 'no-cache',
      variables: queryToGQLVars(query, filters),
      context: propagateErrorsContext(),
    })
    .then(result => {
      const project = result.data.project;
      if (project == null || project.runs == null) {
        throw new Error('Runs query failed with invalid project');
      }
      const nodes = project.runs.edges.map(e => {
        const parsedRun = Run.fromJson(e.node);
        if (parsedRun == null) {
          console.warn("Couldn't parse run from server: ", e.node);
        }
        return parsedRun;
      });
      return {
        totalRuns: project.runs.totalCount,
        runs: nodes.filter(notEmpty),
        lastUpdatedAt:
          _.max(nodes.map(n => n && n.updatedAt)) || new Date(0).toISOString(),
      };
    });
};

function deltaQueryToGQLVars(
  query: ServerQueryDelta,
  filters: FilterTypes.Filter<RunTypes.Key>
): Generated.RunsStateDeltaQueryQueryVariables {
  return {
    ...queryToGQLVars(query, filters),
    currentRuns: query.prevResult.page,
    lastUpdated: query.prevResult.maxUpdatedAt,
  };
}

function deltaQueryToGQLVarsWithProjectId(
  query: ServerQueryDelta,
  filters: FilterTypes.Filter<RunTypes.Key>
): Generated.RunsStateDeltaQueryWithProjectIdQueryVariables {
  return {
    ...queryToGQLVarsWithProjectId(query, filters),
    currentRuns: query.prevResult.page,
    lastUpdated: query.prevResult.maxUpdatedAt,
  };
}

function queryToGQLVarsWithBucketedSampleHistory(
  query: ServerQueryDelta,
  filters: FilterTypes.Filter<RunTypes.Key>
) {
  const {sampledHistorySpecs, ...vars} = deltaQueryToGQLVars(query, filters);

  const bucketedHistorySpecs = query.historySpecs?.map(hs => {
    // this will unfortunately not account for users who have set a config bounds on the x-axis
    // since random sampling does not capture that in the query
    const xStepRange = {
      min: hs.minStep ?? null,
      max: hs.maxStep ?? null,
    };
    // we don't have access to the actual x axis key and metric here, so we infer them from the sampled history spec
    // if the history spec uses _step as the x-axis, then _step is the xAxisKey
    const xAxisKey = hs.keys.length > 2 ? hs.keys[1] : hs.keys[0];
    // the last key is the metric key
    const metricKey = hs.keys[hs.keys.length - 1];
    return JSON.stringify(
      createGorillaHistorySpec({
        xStepRange,
        numberOfPoints: BRSDQ_NUM_BUCKETS,
        metricKey,
        xAxis: xAxisKey,
        xAxisHistoryKeys: hs.keys.slice(0, hs.keys.length - 1),
        useGorillaOutliers: true,
      })
    );
  });
  return {
    ...vars,
    bucketedHistorySpecs: bucketedHistorySpecs,
  };
}

export function parseGqlDeltaOp(op: Generated.RunDiff): ServerDeltaOp {
  if (op.op === 'INSERT' || op.op === 'UPDATE') {
    const parsedRun = Run.fromJson(op.run);
    if (parsedRun == null) {
      throw new Error(
        `Couldn't parse run from server: ${JSON.stringify(op.run)}`
      );
    }
    // typescript really doesn't like this union type for some reason
    if (op.op === 'INSERT') {
      return {
        op: 'INSERT',
        index: op.index,
        run: parsedRun,
      };
    } else {
      return {
        op: 'UPDATE',
        index: op.index,
        run: parsedRun,
      };
    }
  } else if (op.op === 'DELETE') {
    return {
      op: op.op,
      index: op.index,
    };
  }
  throw new Error(`Couldn't parse run diff from server: ${JSON.stringify(op)}`);
}

export const doDeltaQuery = (
  client: ApolloClient,
  query: ServerQueryDelta
): Promise<ServerResultDelta> => {
  let filters = Filter.simplify(query.filters);
  if (filters === false) {
    return Promise.resolve({
      totalRuns: 0,
      delta: _.range(0, query.prevResult.page.length)
        .reverse()
        .map(i => ({op: 'DELETE', index: i})),
    });
  } else if (filters === true) {
    filters = Filter.TRUE_FILTER;
  }

  const deltaQuery =
    query?.internalProjectId != null && query?.internalProjectId !== ''
      ? client.query<Generated.RunsStateDeltaQueryWithProjectIdQuery>({
          query: Generated.RunsStateDeltaQueryWithProjectIdDocument,
          fetchPolicy: 'no-cache',
          variables: deltaQueryToGQLVarsWithProjectId(query, filters),
          context: propagateErrorsContext(),
        })
      : client.query<Generated.RunsStateDeltaQueryQuery>({
          query: Generated.RunsStateDeltaQueryDocument,
          fetchPolicy: 'no-cache',
          variables: deltaQueryToGQLVars(query, filters),
          context: propagateErrorsContext(),
        });

  /**
   * Shadowing the RSDQ for profiling RSDQ:bRSDQ performance
   * For some percentage of traffic (configured by a ramp flag), we want to also run the bucketing query in parallel to compare performance. This is a bit of a hacky way to do it, but it lets us log two identical queries across both endpoints and log comparative data.
   *
   * Because we return the promise from the delta query this shouldn't block execution time while we wait for the brsdq to finish. We just create two new promises that each resolve upon the completion of each query, and await them in this function to log the times to data dog. This should continue in the background without stopping execution.
   */

  const rsdqTimeStart: number = Date.now(); // start the timer for the rsdq
  const brsdqTimeStart = Date.now();
  let resolveBRSDQ: (data: RSDQ_Query_Data) => void;
  let rejectBRSDQ: () => void = () => {
    // this shouldn't ever be hit - it's mostly to satisfy the type checker
    throw new Error('rejectBRSDQ called before assignment');
  };
  let resolveRSDQ: (data: RSDQ_Query_Data) => void;
  let rejectRSDQ: () => void;

  const timer1 = new Promise<RSDQ_Query_Data>((res, rej) => {
    resolveBRSDQ = res;
    rejectBRSDQ = rej;
  });
  const timer2: Promise<RSDQ_Query_Data> = new Promise((res, rej) => {
    resolveRSDQ = res;
    rejectRSDQ = rej;
  });

  processShadow([timer1, timer2]);

  const sendServerShadow = checkServerDeltaQueryShadow();

  /**
   * Run shadow traffic off these queries so we can profile against bRSDQs. Before we run the query though we need to make sure the user has the feature flag on. If the user isn't enrolled in the feature flag, we'll reject the timing query and dump the result
   */

  if (!sendServerShadow) {
    // if the feature flag isn't true, don't run the parallel query. Reject so the promise doesn't hang.
    rejectBRSDQ();
  } else {
    client
      .query({
        query: Generated.BucketedRunsDeltaQueryDocument,
        fetchPolicy: 'no-cache',
        variables: queryToGQLVarsWithBucketedSampleHistory(query, filters),
        context: propagateErrorsContext(),
      })
      .then(r => {
        const {numRuns, numPoints} = unwrapRespBRSDQ(r);
        return resolveBRSDQ({
          elapsedMs: Date.now() - brsdqTimeStart,
          deltaPointsTotal: numPoints,
          deltaRuns: numRuns,
          query: 'brsdq',
        });
      })
      .catch(e => {
        console.error(
          `Error running bucketed shadowed bucketed delta query: ${e}, bucketed history spec: ${JSON.stringify(
            query.historySpecs
          )}`
        );
      });
  }

  return deltaQuery
    .then(result => {
      // @ts-ignore
      const {numPoints, numRuns} = unwrapRespRSDQ(result);
      resolveRSDQ({
        elapsedMs: Date.now() - rsdqTimeStart,
        deltaPointsTotal: numPoints,
        deltaRuns: numRuns,
        query: 'rsdq',
      });
      const project = result.data.project;
      if (project == null || project.runs == null) {
        return {
          totalRuns: 0,
          delta: [],
        };
      }
      /* TS3.9 upgrade caused type mismatch here, because generated gql Run
       type doesn't match our hand-written one */
      const ops = project.runs.delta.map(x => parseGqlDeltaOp(x as any));
      return {
        totalRuns: project.runs.totalCount,
        delta: ops,
      };
    })
    .catch(e => {
      // reject the primary RSDQ fails - we can't log a diff w/out it.
      rejectRSDQ();
      throw e; // preserve consistency with previous behavior
    });
};
